import _ from 'lodash'
import moment from 'moment-timezone'

export function convertTimeToTimeOfDay(time, timeOfDayData) {
  let value = ''
  _.forIn(timeOfDayData, function (band, key) {
    if (isTimeinBand(time, band)) {
      value = key
    }
  })
  return value
}

function isTimeinBand(time, band) {
  const bandFrom = time2int(band.from)
  const bandTo = time2int(band.to)
  time = time.hours() * 60 + time.minutes()
  if (bandFrom < bandTo) {
    if (time >= bandFrom && time < bandTo) {
      return true
    }
  } else {
    // Time band spans midnight
    if (((time >= bandFrom && time < 24 * 60)) || (time >= 0 && time < bandTo)) {
      return true
    }
  }
  return false
}

export function time2int(time) {
  return time.hour * 60 + time.minute
}

export const dateFormatISO8601 = 'YYYY-MM-DDTHH:mm:ss\\Z'
export const dateFormat = 'DD.MM.YYYY'
export const timeFormat = 'HH:mm'
export const dateTimeFormat = 'DD/MM/YYYY HH:mm'

export function basicTime2duration(mo) {
  return !mo ||
  (mo.hour === 0 && mo.minute === 0)
    ? moment('00:00', timeFormat)
    : moment('00:00', timeFormat)
      .add(mo.hour, 'h')
      .add(mo.minute, 'm')
}

export function simpleTimeToDuration(timeStr, timezone) {
  const d = parseInt(timeStr.substr(8, 2)) // "0001-01-01T17:00:00Z"
  const hour = parseInt(timeStr.substr(11, 2)) // "0001-01-01T17:00:00Z"
  const min = parseInt(timeStr.substr(14, 2)) // "0001-01-01T17:00:00Z"
  return moment('00:00', timeFormat).tz(timezone)
    .add(d, 'd')
    .add(hour, 'h')
    .add(min, 'm')

  // return moment.duration({day: d - 1, hour: hour, minute: min})
}

export const formatMomentDuration = (momentDuration) => {
  return formatDuration({
    year: momentDuration.years(),
    month: momentDuration.months(),
    day: momentDuration.days(),
    hour: momentDuration.hours(),
    minute: momentDuration.minutes()
  })
}

export const formatDuration = (duration) => {
  const strParts = []

  if (duration.year && duration.year > 0) {
    strParts.push(duration.year + ' years')
  }

  if (duration.month && duration.month > 0) {
    strParts.push(duration.month + ' months')
  }

  if (duration.day && duration.day > 0) {
    strParts.push(duration.day + 'd')
  }

  if (duration.hour === 1) {
    strParts.push('1h')
  } else if (duration.hour > 1) {
    strParts.push(duration.hour + 'h')
  }

  if (duration.minute > 0) {
    strParts.push(duration.minute + 'm')
  }
  return strParts.join(' ')
}

export function isTimeZero(timeStr) {
  return !timeStr || moment.utc(timeStr).isSame(moment.utc('0001-01-01'))
}

export function camelToSentence(instr) {
  return instr.replace(/([A-Z])/g, ' $1') // camelCase to Camel Case
    .replace(/^./, function (str) {
      return str.toUpperCase()
    }) // uppercase the first character
    .replace(/Service User/g, 'Supported Person')
}
