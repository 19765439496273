import themes from './themes'
import moment from 'moment'

// Some protection... If we are not in development mode, then point to the live site
export default {
  apiUrl: process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://idapi-bolton.cair.uk:443/api/v1'
}

const siteConfig = {
  siteName: 'cAir:ID',
  siteIcon: 'ion-medkit',
  footerText: '© Axela ' + moment().format('YYYY') + ' (v2.1)'
}

const theme = themes.defaultTheme
const language = 'english'

export {siteConfig, language, theme}
